import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { TextField } from '@mui/material';

RHFDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

export default function RHFDatePicker({ name, label, format = 'dd/MM/yyyy HH:mm:ss aa', onChange, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DateTimePicker
          {...field}
          label={label}
          inputFormat={format}
          minDate={new Date()} // Ensures minDate is today in local time
          minTime={new Date()}
          onChange={(date) => {
            if (!date || Object.prototype.toString.call(date) !== '[object Date]' || !date.getTime()) {
              console.warn('Invalid date selected:', date);
              return;
            }
            console.log("date",date);
            
            // Ensure the selected date is interpreted correctly in local time
            // const adjustedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
            // const formattedDate = date.toISOString().split('T')[0].split('/').reverse().join('-');

            // console.log('adjustedDate:', adjustedDate);

            // console.log('Selected Date:', formattedDate);

            field.onChange(date);
            if (onChange) onChange({ target: { name, value: date } });
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
          )}
        />
      )}
    />
  );
}
