import { useSnackbar } from 'notistack';

import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Divider,
  TableBody,
  Container,
  TableContainer,
  TablePagination,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
import useTable, { getComparator, emptyRows } from '../../hooks/useTable';

// components
import Page from '../../components/Page';

import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { TableEmptyRows, TableHeadCustom, TableNoData } from '../../components/table';
// sections
import PollsPublishTableRow from '../../sections/@dashboard/PollPublishTableRow';
import { cityService } from '../../services/city';
import { neighbourhoodService } from '../../services/neighbourhood';
import { apartmentService } from '../../services/apartment';
import { getPollQuestion, publishAdd, pollPublishList, updatepollPublishList } from '../../services/poll';
import { FormProvider, RHFDatePicker, RHFSelect, RHFTextField } from '../../components/hook-form';
import { stateService } from '../../services/states';

// ----------------------------------------------------------------------

const STATUS_OPTIONS = [
  { label: 'all', value: 'all' },
  { label: 'published', value: 'published' },
  { label: 'expired', value: 'expired' },
];

const TABLE_HEAD = [
  { id: 'question', label: 'Questions', align: 'left' },
  { id: 'countryName', label: 'Country', align: 'left' },
  { id: 'stateName', label: 'State', align: 'left' },
  { id: 'cityName', label: 'City', align: 'left' },
  { id: 'neighbourhoodName', label: 'Neighbourhood', align: 'left' },
  { id: 'apartmentName', label: 'Apartment', align: 'left' },
  { id: 'validDate', label: 'Valid Date', align: 'center' },
  // { id: 'status', label: 'Status', align: 'center' },
  { id: 'publishStatus', label: 'Publish Status', align: 'center' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function PollPublish() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [cities, setCities] = useState([]);
  const [neighbourhood, setNeighbourhood] = useState([]);
  const [apartments, setApartments] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [selectCity, setSelectCity] = useState('');
  const [selectNeighbourhood, setSelectNeighbourhood] = useState('');
  const [selectApartment, setSelectApartment] = useState('');
  const [filterName, setFilterName] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs('all');

  const schema = Yup.object().shape({
    questionId: Yup.string().required('Question is required.'),
    cityId: Yup.string().required('City is required.'),
    countryId: Yup.string().required('Country is required.'),
    stateId: Yup.string().required('State is required.'),
    neighbourhoodId: Yup.string(),
    apartmentId: Yup.string(),
    particularAreaKey: Yup.string().required('Please Select Particular Area'),
    validUpto: Yup.date().required('A valid date is required').typeError('Please enter valid date'),
    // validUpto: Yup.date()
    // .required('A valid date is required.')
    // .min(new Date(), 'The date must be today or in the future.')
    // .typeError('Please enter a valid date.'),
  });

  const defaultValues = useMemo(
    () => ({
      id: '',
      countryId: '',
      stateId: '',
      cityId: '',
      neighbourhoodId: '',
      apartmentId: '',
      publishStatus: 'unpublished',
      questionId: '',
      validUpto: '',
      particularAreaKey: '',
    }),
    []
  );

  const method = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, reset, setValue, handleSubmit } = method;
  const values = watch();

  const getCountries = () => {
    stateService
      .getCountryList()
      .then((data) => {
        // console.log(data.data);
        setCountryList(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getStateById = (body) => {
    stateService
      .getStateListByCountryId(body)
      .then((data) => {
        // console.log(data.data);
        setStateList(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    const data = { countryId: values.countryId };
    getStateById(data);
  }, [values.countryId]);

  const getCityList = (data) => {
    setIsLoading(true);
    try {
      cityService
        .getCityListByStateId(data)
        .then((data) => {
          setCities(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, 'error');
          setIsLoading(false);
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    } catch (err) {
      console.log(err, 'err');
      setIsLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    getCityList({ stateId: values.stateId });
  }, [values.stateId]);

  const getNeighbourhoodListById = (id) => {
    setIsLoading(true);
    try {
      const body = { cityId: id };
      neighbourhoodService
        .getNeighbourhoodListByCity(body)
        .then((data) => {
          setNeighbourhood(data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, 'error');
          setIsLoading(false);
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    } catch (err) {
      console.log(err, 'err');
      setIsLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    getNeighbourhoodListById(values.cityId);
  }, [values.cityId]);

  const getApartmentByNeighbourhood = (id) => {
    setIsLoading(true);
    try {
      const body = { neighbourhoodId: id };
      apartmentService
        .getApartmentsByNeighbourhood(body)
        .then((data) => {
          setApartments(data.data);

          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, 'error');
          setIsLoading(false);
          enqueueSnackbar(err.message, { variant: 'error' });
        });
    } catch (err) {
      console.log(err, 'err');
      setIsLoading(false);
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getApartmentByNeighbourhood(values.neighbourhoodId);
  }, [values.neighbourhoodId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPollQuestion();
        setQuestionList(data.data);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);

  const fetchPublishList = async () => {
    try {
      const data = await pollPublishList();
      console.log("Fetch Publish data",data);
      
      setTableData(data.data);
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  useEffect(() => {
    fetchPublishList();
  }, []);

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    setPage(0);
  };

  const handleChange = (event) => {
    setValue(event.target.name, event.target.value);
  };

  const handleEditRow = (row) => {
    setIsEdit(true);
    setValue('id', row?.id);
    setValue('publishStatus', row?.publishStatus);
    setValue('status', row?.status);
    setValue('countryId', row?.countryId);
    setValue('stateId', row?.stateId);
    setValue('cityId', row?.cityId);
    setValue('neighbourhoodId', row?.neighbourhoodId);
    setValue('apartmentId', row?.apartmentId);
    setValue('questionId', row?.questionId);
    setValue('validUpto', row?.validUpto);
    setValue('particularAreaKey', row?.particularAreaKey);
  };
  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.poll.view(id));
  };

  const onSubmit = async (data) => {
    console.log('Onsumit Data', data);

    const {
      neighbourhoodId,
      particularAreaKey,
      apartmentId,
      cityId,
      publishStatus,
      questionId,
      stateId,
      countryId,
      id,
      validUpto,
    } = data;

    const body = {
      neighbourhoodId: particularAreaKey === 'neighbourhoodId' ? neighbourhoodId : '',
      apartmentId: particularAreaKey === 'apartmentId' ? apartmentId : '',
      cityId: particularAreaKey === 'cityId' ? cityId : '',
      publishStatus,
      questionId,
      stateId,
      countryId,
      id,
      validUpto,
    };

    try {
      if (isEdit) {
        updatepollPublishList(body);
      } else {
        publishAdd(body);
      }

      const updatedData = await pollPublishList();
      setTableData(updatedData.data);

      // Reset the form and clear unnecessary fields
      reset({
        ...defaultValues,
        id: '',
        publishStatus: '',
      });

      enqueueSnackbar(isEdit ? 'Updated successfully' : 'Created successfully', { variant: 'success' });
      setIsEdit(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  const dataFiltered =
    applySortFilter({
      tableData: tableData || [], // Ensure tableData is always an array
      comparator: getComparator(order, orderBy),
      filterName,
      filterStatus,
    }) || [];

  const denseHeight = dense ? 52 : 72;

  // Ensure dataFiltered is an array before checking .length
  const isNotFound = (dataFiltered?.length === 0 && !!filterName) || (dataFiltered?.length === 0 && !!filterStatus);

  const particularArea = useMemo(() => {
    const areas = [];

    if (selectCity) {
      areas.push({ id: 0, name: 'City', key: 'cityId' });
    }
    if (selectApartment) {
      areas.push({ id: 1, name: 'Apartment', key: 'apartmentId' });
    }
    if (selectNeighbourhood) {
      areas.push({ id: 2, name: 'Neighbourhood', key: 'neighbourhoodId' });
    }

    return areas;
  }, [selectCity, selectApartment, selectNeighbourhood]); // ✅ Include all dependencies

  return (
    <Page title="Poll Publish: List">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Poll Publish"
          links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Poll Publish' }]}
        />

        <Stack spacing={3}>
          <Card sx={{ p: 3 }}>
            <Typography variant="h4" mb={4}>
              Add Question for Publish
            </Typography>
            <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={3}>
                <Stack direction="row" spacing={3}>
                  <RHFSelect
                    name="countryId"
                    value={values.countryId}
                    label="Country"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>
                    {countryList.map((country) => (
                      <option value={country.id} key={country.id}>
                        {country.countryName}
                      </option>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="stateId"
                    disabled={!values.countryId}
                    value={values.stateId}
                    label="State"
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>
                    {stateList.map((state) => (
                      <option value={state.id} key={state.id}>
                        {state.stateName}
                      </option>
                    ))}
                  </RHFSelect>
                  <RHFSelect
                    name="cityId"
                    disabled={!values.stateId}
                    value={values.cityId}
                    label="City"
                    onChange={(event) => {
                      handleChange(event);
                      setSelectCity(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>
                    {cities.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.cityName}
                      </option>
                    ))}
                  </RHFSelect>
                </Stack>
                <Stack direction="row" spacing={3}>
                  <RHFSelect
                    disabled={!values.cityId}
                    name="neighbourhoodId"
                    value={values.neighbourhoodId}
                    label="Neighbourhood"
                    onChange={(event) => {
                      handleChange(event);
                      setSelectNeighbourhood(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>

                    {neighbourhood?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.neighbourhoodName}
                      </option>
                    ))}
                  </RHFSelect>

                  <RHFSelect
                    disabled={!values.neighbourhoodId}
                    name="apartmentId"
                    value={values.apartmentId}
                    label="Apartment"
                    onChange={(event) => {
                      handleChange(event);
                      setSelectApartment(event.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>

                    {apartments.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.apartmentName}
                      </option>
                    ))}
                  </RHFSelect>

                  {console.log('values.validUpto', values.validUpto)}
                  <RHFTextField
                    name="validUpto"
                    label="Valid Date"
                    type="date"
                    value={values.validUpto}
                    onChange={(e) => {
                      const selectedDate = e.target.value; // Convert string to Date
                      console.log('selectedDate', selectedDate);
                      setValue('validUpto', selectedDate); // Store as Date
                    }}
                    // onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: new Date().toISOString().split('T')[0], // Set minimum selectable date to today
                    }}
                    fullWidth
                  />
                </Stack>
                <Stack direction="row" spacing={3}>
                  <RHFSelect
                    name="questionId"
                    value={values.questionId}
                    label="Question"
                    onChange={(e) => setValue('questionId', e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <option value="">None</option>
                    {questionList.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.question}
                      </option>
                    ))}
                  </RHFSelect>
                  <Box>
                    <Button sx={{ px: 4, py: 2 }} variant="contained" type="submit">
                      {isEdit ? 'Update' : 'Create'}
                    </Button>
                  </Box>
                </Stack>
                <RHFSelect
                  disabled={!particularArea?.length}
                  name="particularAreaKey"
                  label="Select Particular Area"
                  value={values.particularAreaKey}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                >
                  <option value="">Select an option</option>

                  {particularArea.map((state) => (
                    <option key={state.key} value={state.key}>
                      {state.name}
                    </option>
                  ))}
                </RHFSelect>
              </Stack>
            </FormProvider>
          </Card>

          <Card>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={filterStatus}
              onChange={onChangeFilterStatus}
              sx={{ px: 2, bgcolor: 'background.neutral' }}
            >
              {STATUS_OPTIONS.map((tab) => (
                <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>

            <Divider />
            {/* <ProductTableToolbar filterName={filterName} onFilterName={handleFilterName} /> */}

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative', mt: 1 }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData?.length}
                    onSort={onSort}
                  />

                  <TableBody>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <PollsPublishTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onEditRow={() => handleEditRow(row)}
                        onViewRow={() => handleViewRow(row.id)}
                        fetchPublishList={() => fetchPublishList()}
                      />
                    ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, tableData?.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>

            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dataFiltered?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />

              <FormControlLabel
                control={<Switch checked={dense} onChange={onChangeDense} />}
                label="Dense"
                sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
              />
            </Box>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({ tableData = [], comparator, filterName, filterStatus }) {
  if (!Array.isArray(tableData)) {
    console.error('Error: tableData is not an array', tableData);
    return [];
  }

  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let sortedData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    sortedData = sortedData.filter((item) => item.apartment?.toLowerCase().includes(filterName.toLowerCase()));
  }

  if (filterStatus !== 'all') {
    sortedData = sortedData.filter((item) => item.publishStatus === filterStatus);
  }

  return sortedData;
}
