import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';

RHFTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  format: PropTypes.string,
  onChange: PropTypes.func,
};

export default function RHFTimePicker({ name, label, format = 'HH:mm', onChange, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TimePicker
          {...field}
          label={label}
          inputFormat={format}
          value={field?.value || null}
          onChange={(time) => {
            console.log('Time --->', new Date(time).toISOString().slice(11, 19));
            field.onChange(time);
            // if (onChange) onChange({ target: { name, value: new Date(time).toISOString().slice(11, 19)} });
            if (onChange) onChange({ target: { name, value: time } });
          }}
          renderInput={(params) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
          )}
        />
      )}
    />
  );
}
