import axios from '../utils/axios';
import { baseEndpoints } from './config';

export const getPollQuestion = async () => {
  const { data } = await axios.get(`${baseEndpoints.admin}/poll/question_list`);
  return data;
};

export const postPollQuestion = async (body) => {
  try {
    const { data } = await axios.post(`${baseEndpoints.admin}/poll/question_add`, body);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to add question');
  }
};
export const publishAdd = async (body) => {
  try {
    const { data } = await axios.post(`${baseEndpoints.admin}/poll/publish_add`, body);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to add question');
  }
};
export const pollPublishList = async () => {
  try {
    const { data } = await axios.get(`${baseEndpoints.admin}/poll/publish_list`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to list ');
  }
};
export const updatepollPublishList = async (body) => {
  try {
    const { data } = await axios.put(`${baseEndpoints.admin}/poll/publish_update`,body);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to update question');
  }
};
export const editPollQuestion = async (body) => {
  try {
    const { data } = await axios.put(`${baseEndpoints.admin}/poll/question_update`,body);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to add question');
  }
};
export const changePollStatus = async (body) => {
  try {
    const { data } = await axios.put(`${baseEndpoints.admin}/poll/publish`,body);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to add question');
  }
};
export const getPollResult = async (id) => {
  try {
    const { data } = await axios.get(`${baseEndpoints.admin}/poll/poll_response_graph/${id}`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    throw new Error(error.response.data.message || 'Failed to Fetch Chart Data');
  }
};


